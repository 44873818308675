const APP_NAME_IIMJOBS = 'iimjobs';
const APP_NAME_HIRIST = 'hirist';
const THEME = 'theme';
const HOME = 'home';
const CATEGORY = 'category';
const LOCATION_BASED = 'location_based';
const EPOCH_ROUND_ONE = 'epoch-round-one';
const EPOCH_ROUND_TWO = 'epoch-round-two';
const EPOCH_ROUND_THREE = 'epoch-round-three';
const EPOCH = 'epoch';
const EPOCH_REGISTER = 'epoch-register';
const EPOCH_TERMS = 'epoch-terms';
const EPOCH_WINNERS = 'epoch-winners';
const UNSUBSCRIBE = 'unsubscribe';
const TAG = 'tag';
const APP = 'app';
const NOTFOUND = 'notfound';
const DOWNLOADAPP = 'downloadapp';
const ABOUTUS = 'aboutus';
const COMPANY = 'company';
const CONTACTUS = 'contact';
const REFUND = 'refund';
const PRIVACY = 'privacy';
const COOKIEPOLICY = 'cookiepolicy';
const TERMS = 'terms';
const FAQ = 'faq';
const CHATS = 'chats';
const SITEMAP = 'sitemap';
const DEACTIVATE = 'deactivate';
const INTERVIEWS = 'interviews';
const VIDEO_CHAT = 'interview';
const JOBFEED = 'jobfeed';
const RECRUITER_JOBFEED = 'recruiterJobfeed';
const RESET_PASSWORD = 'resetpassword';
const SAVEDJOBS = 'savedjobs';
const APPLIEDJOBS = 'appliedjobs';
const RECRUITER_VIEW = 'recruiterview';
const NOTIFICATIONS = 'allnotification';
const JOBAPPLIED = 'jobapplied';
const SETTINGS = 'settings';
const JOBDETAIL = 'jobdetail';
const RECOMMENDED_JOBS = 'recommended-jobs';
const APPLIEDJOB = 'appliedjob';
const SEARCH = 'search';
const COOKIE_CKT = '_CK1';
const COOKIE_LASTACT = '_LASTACT';
const LOCATION = 'location';
const PERSONALIZE_JOBFEED = 'personalize-jobfeed';
const REGISTRATION = 'registration';
const REGISER_PERSONAL_DETAILS = 'registration-personaldetail';
const REGISTER_WORK_EXPERIENCE = 'registration-workexperience';
const REGISTER_EDUCATIONAL_DETAILS = 'registration-educationaldetail';
const REGISTER_ADDITIONAL_DETAILS = 'registration-additionaldetail';
const REGISTER_COVERLETTERANDSKILLS = 'registration-coverletterandskills';
const REGISTER_AUDIO_VIDEO_PROFILE = 'registration-audiovideoprofile';
const BOTUSERAGENTS = [
  'googlebot',
  'Googlebot-Image',
  'Bingbot',
  'Slurp',
  'Google-InspectionTool',
  'Googlebot-News',
  'Googlebot-Video',
  'Storebot-Google',
  'GoogleOther',
  'BingPreview',
  'MicrosoftPreview',
];
const MYJOBFEED = 'My Jobfeed';
const SLUG_SUFFIX = '-jobs';
const REF_REDIRECT_OLD = 'red_old';

const EXPERIENCE_MAPPING: Array<object> = [
  {minexp: 0, maxexp: 30},
  {minexp: 1, maxexp: 3},
  {minexp: 4, maxexp: 6},
  {minexp: 7, maxexp: 10},
  {minexp: 11, maxexp: 15},
  {minexp: 15, maxexp: 100},
];
const PRO_MEMBERSHIP = 'pro-membership';

export {
  APP_NAME_IIMJOBS,
  APP_NAME_HIRIST,
  THEME,
  HOME,
  APP,
  NOTFOUND,
  DOWNLOADAPP,
  CATEGORY,
  LOCATION_BASED,
  JOBFEED,
  SAVEDJOBS,
  RECRUITER_VIEW,
  NOTIFICATIONS,
  JOBAPPLIED,
  APPLIEDJOBS,
  TAG,
  JOBDETAIL,
  RECOMMENDED_JOBS,
  SEARCH,
  APPLIEDJOB,
  COOKIE_CKT,
  COOKIE_LASTACT,
  BOTUSERAGENTS,
  MYJOBFEED,
  SLUG_SUFFIX,
  EXPERIENCE_MAPPING,
  REF_REDIRECT_OLD,
  RESET_PASSWORD,
  ABOUTUS,
  COMPANY,
  CONTACTUS,
  REFUND,
  PRIVACY,
  COOKIEPOLICY,
  TERMS,
  FAQ,
  CHATS,
  LOCATION,
  EPOCH_ROUND_ONE,
  EPOCH,
  EPOCH_REGISTER,
  EPOCH_ROUND_TWO,
  EPOCH_ROUND_THREE,
  EPOCH_TERMS,
  EPOCH_WINNERS,
  REGISTRATION,
  REGISER_PERSONAL_DETAILS,
  REGISTER_WORK_EXPERIENCE,
  REGISTER_EDUCATIONAL_DETAILS,
  REGISTER_ADDITIONAL_DETAILS,
  REGISTER_COVERLETTERANDSKILLS,
  REGISTER_AUDIO_VIDEO_PROFILE,
  PRO_MEMBERSHIP,
  UNSUBSCRIBE,
  PERSONALIZE_JOBFEED,
  DEACTIVATE,
  SETTINGS,
  SITEMAP,
  RECRUITER_JOBFEED,
  INTERVIEWS,
  VIDEO_CHAT,
};

const ROUTE_JOBFEED = '/jobfeed';
const ROUTE_NOTFOUND = '/notfound';
const ROUTE_SEARCH = '/search';
const ROUTE_HOME = '/';
const ROUTE_JOB_DETAIL = '/j';
const ROUTE_CATEGORY = '/c';
const ROUTE_TAG = '/k';
const ROUTE_FILTER = '/filter';
const ROUTE_JOB_APPLIED = '/job/applied';
const ROUTE_SAVED_JOBS = '/saved-jobs';

const NON_CITY_LOCATION_IDS = [
  0, 8, 9, 10, 13, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 30,
  31, 32, 33, 34, 35, 59, 64, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98,
  99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114,
  115, 116, 117, 118, 119, 120, 122, 123, 124, 125, 126, 127, 128, 129, 132,
  100000,
];

const MAJOR_CITIES = [
  'mumbai',
  'bangalore',
  'delhi-ncr',
  'hyderabad',
  'chennai',
  'pune',
];

export {
  ROUTE_JOBFEED,
  ROUTE_NOTFOUND,
  ROUTE_SEARCH,
  ROUTE_HOME,
  ROUTE_JOB_DETAIL,
  ROUTE_JOB_APPLIED,
  ROUTE_CATEGORY,
  ROUTE_TAG,
  ROUTE_FILTER,
  ROUTE_SAVED_JOBS,
  NON_CITY_LOCATION_IDS,
  MAJOR_CITIES,
};
